import {castArray} from 'lodash';
import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Event,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  QueryParamsHandling,
  Router,
} from '@angular/router';
import {action, computed, makeObservable, observable} from 'mobx';

import {NullableProps} from '@shared/types/lib';
import {RouterUrl} from '@shared/types/angular';

type Params<TValue = any> = Record<string, TValue>;

interface NavigationParams<TNavigationState extends NavigationExtras['state'] = NavigationExtras['state']> {
  state?: TNavigationState;
  replaceUrl?: NavigationExtras['replaceUrl'];
  queryParamsHandling?: QueryParamsHandling | null;
}

interface UpdateQueryParams<TNavigationState extends NavigationExtras['state'] = NavigationExtras['state']>
  extends NavigationParams<TNavigationState> {
  overwrite?: boolean;
}

interface UpdateRouterStateParams {
  url?: boolean;
  params?: boolean;
  navigationState?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RouterService<
  TParams extends object = Params<string>,
  TQueryParams extends object = Params<string>,
  TNavigationState extends NavigationExtras['state'] = NavigationExtras['state'],
> {
  @observable url: string;
  @observable.deep readonly params: TParams = {} as TParams;
  @observable.deep readonly query: Partial<TQueryParams> = {};
  @observable.deep readonly navigationState: Partial<TNavigationState> = {};

  @observable protected previousUrl: string | null = null;
  protected navigationStartEvent: NavigationStart | null = null;

  constructor(private router: Router) {
    makeObservable(this);

    this.updateRouterState();
    this.router.events.subscribe(this.handleRouterEvents);
  }

  @computed
  get search(): string {
    const index = this.url.indexOf('?');

    return index === -1 ? '' : this.url.slice(index);
  }

  @computed
  get queryString(): string {
    return this.search.slice(1);
  }

  @computed
  get hash(): string {
    const index = this.url.indexOf('#');

    return index === -1 ? '' : this.url.slice(index);
  }

  @computed
  get fragment(): string {
    return this.hash.slice(1);
  }

  @computed
  get canGoBack(): boolean {
    return Boolean(this.previousUrl);
  }

  @action
  navigate(...args: Parameters<Router['navigate']>): ReturnType<Router['navigate']> {
    return this.router.navigate(...args);
  }

  @action
  navigateByUrl(url: string | RouterUrl, params?: NavigationParams<TNavigationState>): Promise<boolean> {
    if (typeof url === 'string') {
      return this.router.navigateByUrl(url, params);
    } else {
      return this.router.navigate(castArray(url.path), {
        ...params,
        queryParams: url.query,
        fragment: url.fragment,
      });
    }
  }

  @action
  navigateBack(fallbackUrl = '/'): Promise<boolean> {
    return this.navigateByUrl(this.previousUrl || fallbackUrl);
  }

  @action
  updateQuery(
    query: Partial<NullableProps<TQueryParams>>,
    params: UpdateQueryParams<TNavigationState> = {},
  ): Promise<boolean> {
    return this.router.navigate([], {
      queryParams: query,
      queryParamsHandling: params.overwrite ? null : 'merge',
      preserveFragment: true,
      replaceUrl: params.replaceUrl,
      state: params.state,
    });
  }

  @action
  updateFragment(fragment: string | null, params?: NavigationParams<TNavigationState>): Promise<boolean> {
    return this.router.navigate([], {
      fragment: fragment ?? undefined,
      queryParamsHandling: 'preserve',
      ...params,
    });
  }

  @action.bound
  protected handleRouterEvents(event: Event) {
    if (event instanceof NavigationStart) {
      this.navigationStartEvent = event;
      this.updateRouterState({navigationState: true});
    } else if (event instanceof NavigationEnd) {
      const navigationExtras = this.getNavigationExtras()!;

      if (
        !navigationExtras.replaceUrl ||
        // `popstate` trigger always has `replaceUrl: true` flag set
        this.navigationStartEvent?.navigationTrigger === 'popstate'
      ) {
        this.previousUrl = this.url;
      }

      this.navigationStartEvent = null;
      this.updateRouterState({url: true, params: true});
    }
  }

  @action
  protected updateRouterState(
    updateParams: UpdateRouterStateParams = {url: true, params: true, navigationState: true},
  ) {
    const {url, root: rootSnapshot} = this.router.routerState.snapshot;

    if (updateParams.url) {
      this.url = url;
    }

    if (updateParams.params) {
      this.updateStateObject(this.params, this.collectRouteParams(rootSnapshot));
      this.updateStateObject(this.query, rootSnapshot.queryParams);
    }

    if (updateParams.navigationState) {
      this.updateStateObject(this.navigationState, this.getNavigationExtras()?.state || {});
    }
  }

  @action
  protected updateStateObject(currentParams: Params, newParams: Params): void {
    for (const key of Object.keys(currentParams)) {
      if (!newParams.hasOwnProperty(key)) {
        delete currentParams[key];
      }
    }

    Object.assign(currentParams, newParams);
  }

  protected getNavigationExtras(): NavigationExtras | undefined {
    return this.router.getCurrentNavigation()?.extras;
  }

  protected collectRouteParams(rootSnapshot: ActivatedRouteSnapshot): TParams {
    const params = {} as TParams;
    const stack: ActivatedRouteSnapshot[] = [rootSnapshot];

    while (stack.length > 0) {
      const route = stack.pop()!;

      Object.assign(params, route.params);
      stack.push(...route.children);
    }

    return params;
  }
}
